import { Typography, Grid } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import { pink } from "@mui/material/colors";

export default function Loading() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", textAlign: "center" }}
    >
      <Grid item>
        <CircleLoader variant="h2" color="#e91e63" size={70} />
        <Typography variant="h3" color={pink[500]}>
          Betöltés...
        </Typography>
      </Grid>
    </Grid>
  );
}
